import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { FavCorpDialogData } from 'app/comp/admin/trade/filter/fav-corp-dialog/fav-corp-dialog.component';
import { saveFilterDialogData } from 'app/comp/admin/trade/filter/save-dialog/save-dialog.component';
import { FavCorp, FavCriteriaFilter, FavFilter } from 'app/types/domain/transaction';
import { createCleanedPartial } from 'app/utils/object.utils';
import { BehaviorSubject, Observable, Subscribable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FavService {

  private _favFilter = new BehaviorSubject<FavFilter[]>([]);;
  private _totalRows$ = new BehaviorSubject<number>(0);

  private _favCorps = new BehaviorSubject<FavCorp[]>([]);
  private _totalRowsFavCorp$ = new BehaviorSubject<number>(0);

  constructor(private _httpClient: HttpClient, private _configService:ConfigService) { }

  get favFilters(): Observable<FavFilter[]> { 
    return this._favFilter.asObservable();
  }
  get totalRows(): Observable<number> {
    return this._totalRows$.asObservable();
  }

  get favCorps(): Observable<FavCorp[]> {
    return this._favCorps.asObservable();
  } 
  get totalRowsFavCorp(): Observable<number> {
    return this._totalRowsFavCorp$.asObservable();
  }

  saveFilter(config: saveFilterDialogData): Observable<FavFilter> {
    const filterConfig: FavFilter = {
        name: config.filterName,
        filter: config.filterConfig
    };
    return this._httpClient.post<FavFilter>(this._configService.formatUrl('favFilterUrl') + "/create", filterConfig)
    .pipe();
  }
  updateFilter(config: saveFilterDialogData, filter:FavFilter): Observable<FavFilter>{
    return this._httpClient.put<FavFilter>(this._configService.formatUrl('favFilterUrl') + "/update", 
    Object.assign(filter, {name: config.filterName, filter: config.filterConfig}));
  }

  queryFavFilters(filter:FavCriteriaFilter = {}): Subscription {
    filter = createCleanedPartial<FavCriteriaFilter>(filter);
    //console.debug('filterService.getFavFilters', filter);
      return this._httpClient
      .post<QueryResult<FavFilter>>(this._configService.formatUrl('favFilterUrl') + "/query", filter)
      .subscribe(
          (result:QueryResult<FavFilter>) => {
            this._favFilter.next(result.items);
            this._totalRows$.next(result.rows);
          }
      );
  }

  deleteFilter(filter:FavFilter):Observable<any>{
    return this._httpClient.delete(this._configService.formatUrl('favFilterUrl') + "/delete",{params:{id:filter.favFilterId!}})
  }

  saveFavCorp(data: FavCorpDialogData){
    return this._httpClient.post(this._configService.formatUrl('favCorpUrl') + "/create", {
      name: data.favCorp,
      favCorpTypes: data.type
    });
  }

  queryFavCorps(filter:FavCriteriaFilter = {}): Subscription {
    filter = createCleanedPartial<FavCriteriaFilter>(filter);
    return this._httpClient.post<QueryResult<FavCorp>>(this._configService.formatUrl('favCorpUrl') + "/query", filter)
    .subscribe((result:QueryResult<FavCorp>) => {
      this._favCorps.next(result.items);
      this._totalRowsFavCorp$.next(result.rows);
    });
  }
  
  deleteCorp(corp:FavCorp){
    return this._httpClient.delete(this._configService.formatUrl('favCorpUrl') + "/delete", {params:{id:corp.favCorpId!}});
  }

  updateCorp(data: FavCorpDialogData, corp:FavCorp){
    return this._httpClient
    .put(this._configService.formatUrl('favCorpUrl') + "/update", 
    Object.assign(corp, {name: data.favCorp, favCorpTypes: data.type})
    );
  }

}
